import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useNavigate } from "react-router-dom";

import ReactMarkdown from "react-markdown";
import "./ParentsPage.scss";
import { LoadingSlider } from "src/components/LoadingSlider/LoadingSlider";
import { LoadingSpinner } from "src/components/LoadingSpinner/LoadingSpinner";
import { getPageContentByType } from "src/utils/renderUtils";
import RichText from "../components/RichText/RichText";
import {useParents} from "../hooks/useParents";
import Gallery from "../components/Gallery/Gallery";
import Lightbox from "yet-another-react-lightbox";

function ParentsPage() {
  const { t } = useTranslation();

  const navigate = useNavigate();

  // lightbox status
  const [index, setIndex] = React.useState(-1);

  const {
    isLoading: isParentsLoading,
    error: parentsError,
    data: parentsData,
  } = useParents();

  const goBack = () => {
    navigate(-1);
  };

  if (isParentsLoading)
    return (
      <>
        <LoadingSlider />
        <LoadingSpinner />
      </>
    );

  if (parentsError || !parentsData) return <span>"An error has occurred: "</span>;

  const renderImagesElement = () => {
    const slides = parentsData?.attributes?.top_block?.images?.data?.map((item) => {
      return {
        src: item.attributes.url,
      };
    })

    return (
        <>
          {slides && slides.length > 0 && (
              <>
                <Gallery slides={slides} setIndex={setIndex} />
                <Lightbox open={index >= 0} index={index} close={() => setIndex(-1)} slides={slides} />
              </>
          )}
        </>
    )
  }

  const renderBoxElement = (item: any, key: number) => {
    return (
        <div className={`parents-box-item`} key={key}>
          <div className={`parents-box-item-image`}>
            <img src={item.image.data.attributes.url} alt={`icon`} />
          </div>
          <h3>{item.label}</h3>
        </div>
    )
  }

  return (
    <div className="ParentsPage">
      <div className="page-content">
        <div className="page-header">
          <div className="back-icon" onClick={goBack} />
          <h1>{t("parents")}</h1>
        </div>
        {parentsData && (
        <>
          <div className={`parents-top`}>
            <h2 className={`parents-top-heading`}>{parentsData.attributes.top_block.heading}</h2>
            <RichText content={parentsData.attributes.top_block.text_content} />
            {renderImagesElement()}
          </div>

          <div className={`parents-middle`}>
            <RichText content={parentsData.attributes.middle_block.text_content} />
            <div className={`parents-boxes`}>
              {parentsData.attributes.middle_block.items && parentsData.attributes.middle_block.items.length > 0 && parentsData.attributes.middle_block.items.map((item: any, index: number) => {
                  return renderBoxElement(item, index)
              })}
            </div>
          </div>

          <div className={`parents-bottom`}>
            <RichText content={parentsData.attributes.bottom_block.text_content} />
            <img src={parentsData.attributes.bottom_block.image.data.attributes.url} alt={``} className={`parents-bottom-img`} />
          </div>
        </>
        )}
      </div>
    </div>
  );
}

export default ParentsPage;
