/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useLayoutEffect, useEffect } from 'react';
import { Breakpoint, Size, ResponsiveLayout } from './Breakpoint';

export interface IDevice {
    isMobile: boolean;
    isTablet: boolean;
    isDesktop: boolean;
    isLargeDesktop: boolean;
    isExtraLargeDesktop: boolean;
    breakpointChanged: boolean;
    breakpoint: Size;
    breakpointName: ResponsiveLayout;
    width: number;
    height: number;
    isPortrait: boolean;
    orientationChanged: boolean;
}

export const useDevice = () => {
    const [currentDevice, setCurrentDevice] = useState<IDevice>({ isMobile: false, isTablet: false, isDesktop: false, isLargeDesktop: false, isExtraLargeDesktop: false, breakpointChanged: true, breakpoint: Size.Xs, breakpointName: ResponsiveLayout.Tablet, width: 0, height: 0, isPortrait: true, orientationChanged: false });

    if (typeof window === 'object') {
        useEffect(() => {
            let lastDevice: IDevice | null | undefined = null;

            function updateSize() {
                const { innerWidth: width, innerHeight: height } = window;

                const newDevice: IDevice = {
                    isMobile: false,
                    isTablet: false,
                    isDesktop: false,
                    isLargeDesktop: false,
                    isExtraLargeDesktop: false,
                    breakpointChanged: false,
                    breakpoint: Size.Lg,
                    breakpointName: ResponsiveLayout.LargeDesktop,
                    width,
                    height,
                    isPortrait: width < height,
                    orientationChanged: false
                };

                if (width < Breakpoint[Size.Sm].width) {
                    newDevice.isMobile = true;
                    newDevice.breakpoint = Size.Xs;
                    newDevice.breakpointName = ResponsiveLayout.Mobile;
                } else if (width < Breakpoint[Size.Md].width) {
                    newDevice.isTablet = true;
                    newDevice.breakpoint = Size.Sm;
                    newDevice.breakpointName = ResponsiveLayout.Tablet;
                } else if (width < Breakpoint[Size.Lg].width) {
                    newDevice.isDesktop = true;
                    newDevice.breakpoint = Size.Md;
                    newDevice.breakpointName = ResponsiveLayout.Desktop;
                
                } else if (width < Breakpoint[Size.XLg].width) {
                    newDevice.isDesktop = true;
                    newDevice.isLargeDesktop = true;
                    newDevice.breakpoint = Size.Lg;
                    newDevice.breakpointName = ResponsiveLayout.Desktop;
                } else {
                    newDevice.isExtraLargeDesktop = true;
                }

                setCurrentDevice(newDevice);
                lastDevice = newDevice;
            }

            window.addEventListener('resize', updateSize);
            window.addEventListener('orientationchange', updateSize);
            updateSize();

            return () => {
                window.removeEventListener('resize', updateSize);
                window.removeEventListener('orientationchange', updateSize);
            };
        }, []);
    }

    return currentDevice;
};
