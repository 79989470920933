import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { getLocale } from "src/components/HeaderModule/HeaderModule";
import { ParentsProps } from "src/components/Interfaces";
import { appSettings } from "src/config";

export const useParents = () => {
   return useQuery<ParentsProps>(["homepageProps"], () =>
    axios
      .get(
        `${appSettings.cmsApiUrl}/parents-page?populate[0]=top_block&populate[1]=top_block.images&populate[2]=middle_block&populate[3]=middle_block.items&populate[4]=middle_block.items.image&populate[5]=bottom_block&populate[6]=bottom_block.image&locale=${getLocale()}`
      )
      .then((res) => {
        const returnValue = res.data;
        return returnValue.data;
      })
  );
}
