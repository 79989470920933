import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDevice } from "src/hooks/useDevice";
import Typography from "@mui/material/Typography";
import { Button, Checkbox, FormControlLabel, styled } from "@mui/material";

import "./ArchivePage.scss";
import { Activity, Page, Filter } from "src/components/Interfaces";
import AddIcon from "@mui/icons-material/Add";
import ActivityTile from "src/components/ActivityTile/ActivityTile";
import { useActivityCategories } from "src/hooks/useActivityCategories";
import { useActivities } from "src/hooks/useActivities";
import { usePages } from "src/hooks/usePages";
import { decodeKey, encodeKey } from "src/utils/urlUtils";
import { LoadingSlider } from "src/components/LoadingSlider/LoadingSlider";
import { LoadingSpinner } from "src/components/LoadingSpinner/LoadingSpinner";

const ACTIVITY_INCREMENT: number = 1000;

function ArchivePage() {
  const { t } = useTranslation();
  const [archivedActivities, setArchivedActivities] = useState<Activity[]>([]);

  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState<Filter[]>([]);
  const [archivedActivitiesCount, setArchivedActivitiesCount] =
      useState<number>(1000);
  const [showLoadMoreButton, setShowLoadMoreButton] = useState<boolean>(true);

  const {
    isLoading: isActivityCategoriesLoading,
    error: activityCategoriesError,
    data: activityCategoriesData,
  } = useActivityCategories();

  const {
    isLoading: isActivitiesLoading,
    error: activitiesError,
    data: activitiesData,
  } = useActivities(true);

  const {
    isLoading: isPagesLoading,
    error: pagesError,
    data: pagesData,
  } = usePages();

  useEffect(() => {
    if (activitiesData) {
      filterActivities();
    }
  }, [activitiesData]);

  useEffect(() => {
    initializeFilters();
  }, [activityCategoriesData]);

  const initializeFilters = () => {
    if (activityCategoriesData) {
      const _filters: Filter[] = [];

      //default initialization
      activityCategoriesData.forEach((item) => {
        _filters.push({
          id: item.id,
          label: item.attributes.name,
          isActive: true,
        });
      });

      //setup active filters
      searchParams.forEach((v, k) => {
        if (k === "filters") {
          let keys = v.split(",");
          let decodedKeys: string[] = [];

          keys.map((key) => {
            let decodedKey = decodeKey(key);
            decodedKeys.push(decodedKey);
          });

          _filters.forEach((filter) => {
            filter.isActive = decodedKeys.indexOf(filter.id.toString()) > -1;
          });
        }
        if (k === "count") {
          const count = Number(v);
          if (count)
            setArchivedActivitiesCount(count);
        }
      });

      setFilters(_filters);
    }
  }

  useEffect(() => {
    filterActivities();
  }, [filters]);

  useEffect(() => {
    filterActivities();
  }, [archivedActivitiesCount]);


  const isCategoryChecked = (filterId: number) => {
    const filter = filters?.find((item) => item.id === filterId);
    if (filter) return filter.isActive;

    return false;
  };

  const filterActivities = () => {
    const filteredActivities: Activity[] = [];
    let totalCount = 0;

    activitiesData?.forEach((item, index) => {
      const category = item.attributes.activity_category?.data?.id;
      if (category && isCategoryChecked(category)) {
        if (totalCount < archivedActivitiesCount) {
          totalCount++;
          filteredActivities.push(item);
        }
      }
    });

    setArchivedActivities(filteredActivities);
    checkLoadMoreButton();
  };

  const showMoreActivities = () => {
    const newValue = archivedActivitiesCount + ACTIVITY_INCREMENT;
    setArchivedActivitiesCount(newValue);
    // updatePageUrl(newValue);
  };

  const checkMoreActivities = (): boolean => {
    const totalCount = getTotalActivitiesCount();
    return totalCount > archivedActivitiesCount
  };

  const getTotalActivitiesCount = () => {
    let totalCount = 0;

    activitiesData?.forEach((item) => {
      const category = item.attributes.activity_category?.data?.id;
      if (category && isCategoryChecked(category)) {
        totalCount++;
      }
    });

    return totalCount;
  }

  const checkLoadMoreButton = () => {
    setShowLoadMoreButton(checkMoreActivities());
  }

  const handleFilterItemChange = (item: Filter) => {
    item.isActive = !item.isActive;
    // updatePageUrl(archivedActivitiesCount);
    filterActivities();
  };

  const updatePageUrl = (_archivedActivitiesCount:number) => {
    let keys: string = "";

    filters.forEach((filter) => {
      if (filter.isActive) {
        if (keys.length > 0) keys = keys + ",";
        keys = keys + encodeKey(filter.id.toString());
      }
    });

    setSearchParams({
      filters: keys,
      count: _archivedActivitiesCount.toString()
    });
  };

  const renderFilterItem = (item: Filter) => {
    return (
        <FormControlLabel
            key={`filter_${item.id}`}
            control={
              <Checkbox
                  checked={item.isActive}
                  style={{ color: "#004F31" }}
                  onChange={() => handleFilterItemChange(item)}
              />
            }
            label={<Typography style={{fontWeight: 'bolder'}}>{item.label}</Typography>}
        />
    );
  };

  if (isActivityCategoriesLoading || isActivitiesLoading || isPagesLoading)
    return (
        <>
          <LoadingSlider />
          <LoadingSpinner />
        </>
    );

  if (activityCategoriesError || activitiesError || pagesError)
    return <span>"An error has occurred: "</span>;

  return (
      <div className="ArchivePage">
        <div className="page-content">
          <div className="filter-part">
            <div className="filter">
              <div className="filter-text">{t("filter")}</div>
              <div className="filter-items">
                {filters?.map((filter) => {
                  return renderFilterItem(filter);
                })}
              </div>
            </div>
          </div>
          <div className="activities-list">
            {archivedActivities?.map((activityItem, index) => {
              return <ActivityTile key={`activity_item_${activityItem.id}`} activity={activityItem} isArchive={true} />;
            })}
          </div>
          {showLoadMoreButton && (
              <div className="load-more-button-part">
                <Button
                    className="load-more-button"
                    style={{ textTransform: "none" }}
                    onClick={showMoreActivities}
                    startIcon={<AddIcon style={{ color: '#25B036' }} />}
                >
                  {t("moreActivities")}
                </Button>
              </div>
          )}
        </div>
      </div>
  );
}

export default ArchivePage;
