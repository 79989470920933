import { useTranslation } from "react-i18next";
import './HomepageIntro.scss'
import { HomepageProps } from "../Interfaces";
import RichText from "../RichText/RichText";

interface IHomepageIntroProps {
    homepage: HomepageProps
}

const HomepageIntro = (props: IHomepageIntroProps) => {
    const { t } = useTranslation();

    return (
        <div className={`homepage-intro--wrapper`}>
            <div className={`homepage-intro--content`}>
                <h1 className={`homepageIntro--heading`}><RichText content={props.homepage.attributes.heading} /></h1>
                <RichText content={props.homepage.attributes.text} />
            </div>
            <div className={`homepage-intro--image`}>
                <img src={props.homepage.attributes.image.data.attributes.url} alt={`Měsíc školních zahrad`} />
            </div>
        </div>
    )
}

export default HomepageIntro;