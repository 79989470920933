import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { getLocale } from "src/components/HeaderModule/HeaderModule";
import { Activity } from "src/components/Interfaces";
import { appSettings } from "src/config";

export const useActivities = (isArchived:boolean) => {
   return useQuery<Activity[]>(["activitiesData"], () =>
    axios
      .get(
        `${appSettings.cmsApiUrl}/activities?sort=Order&filters[IsArchived][$eq]=${isArchived}&populate=activity_category,TileImage&locale=${getLocale()}`
      )
      .then((res) => {
        const returnValue = res.data;
        return returnValue.data;
      })
  );
}
