import './Gallery.scss'

interface GalleryProps {
    slides: {
        src: string
    }[]
    setIndex: (index: number) => any
}

const Gallery = (props: GalleryProps) => {
    return (
        <div className={`gallery`}>
            {props.slides && props.slides.length > 0 && props.slides.map((slide, index) => {
                return (
                    <div key={index} className={`gallery-slide`}>
                        <img src={slide.src} alt={`activity-image-${index}`} onClick={() => props.setIndex(index)} />
                    </div>
                )
            })
            }
        </div>
    )
}

export default Gallery;