import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { ActivitySettings } from "src/components/Interfaces";
import { appSettings } from "src/config";

export const useActivitySettings = () => {
    return useQuery<ActivitySettings[]>(["activitySettingsData"], () =>
    axios
      .get(
        `${appSettings.cmsApiUrl}/activity-settings?sort=order`
      )
      .then((res) => {
        const returnValue = res.data;
        return returnValue.data;
      })
  );
}