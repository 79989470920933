import { useTranslation } from "react-i18next";
import { appSettings } from "src/config";
import { Activity } from "../Interfaces";
import "./ActivityTile.scss";
import {getColorByType} from "../../utils/activityColors";

interface ILinkProps {
  activity: Activity;
  isArchive: boolean;
}

const ActivityTile = (props: ILinkProps) => {
  const { t } = useTranslation();

  // const tileImageUrl = appSettings.cmsUrl + props.activity.attributes.TileImage.data.attributes.url; INFO - original line
  const tileImageUrl = props.activity.attributes.TileImage.data.attributes.formats?.small?.url ?? props.activity.attributes.TileImage.data.attributes.url;

  return (
    <a
      data-component="activity-item"
      href={`/detail?id=${props.activity.id}`}
    >
      <div className="activity-name">{props.activity.attributes.Header}</div>
      <div className="activity-image">
        <img src={tileImageUrl} alt="activity" />
      </div>

      <div className="tags">
        {props.isArchive && props.activity.attributes.activity_category && (
        <div
          className="activity-tag"
          style={{
            color:
              props.activity.attributes.activity_category.data.attributes.color,
            borderColor:
              props.activity.attributes.activity_category.data.attributes.color,
          }}
        >
          {props.activity.attributes.activity_category.data.attributes.name}
        </div>
        )}
        {!props.isArchive && props.activity.attributes.type && (
            <div
                className="activity-tag"
                style={{
                  color: getColorByType(props.activity.attributes.type ?? ''),
                  borderColor: getColorByType(props.activity.attributes.type ?? ''),
                }}
            >
              {t(`types.${props.activity.attributes.type}`)}
            </div>
        )}
        {!props.isArchive && props.activity.attributes.tag && (
            <div
                className="activity-tag"
                style={{
                  color: getColorByType(''),
                  borderColor: getColorByType(''),
                }}
            >
              {props.activity.attributes.tag}
            </div>
        )}

        {/*
        {props.isArchive && (
          <div>
            <div className="activity-tag activity-archive-tag">
              <div className="activity-archive-icon"/>
              <span>{t("archive")}</span>
            </div>
          </div>
        )}
        */}
      </div>
    </a>
  );
};

export default ActivityTile;
