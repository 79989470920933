interface AppSettings {
    cmsUrl: string;
    cmsApiUrl: string;
    facebookUrl: string;
    newsletter:string
    nadacePromenyUrl:string
    ucimeSeVenkuUrl:string
}

export const appSettings: AppSettings = (window as any).appSettings;

if (window.location.hostname === 'localhost') {
    appSettings.cmsUrl = "https://admin-mesicskolnichzahrad-cz.herokuapp.com";
    appSettings.cmsApiUrl = "https://admin-mesicskolnichzahrad-cz.herokuapp.com/api";
    appSettings.facebookUrl = "https://www.facebook.com/groups/151143705522166";
    appSettings.newsletter = "https://us12.list-manage.com/subscribe?u=b811ff0ff0dafbe51696e66ec&id=e7392e1076";
    appSettings.nadacePromenyUrl = "https://www.nadace-promeny.cz/";
    appSettings.ucimeSeVenkuUrl = "https://ucimesevenku.cz/"
}
