import React, { useEffect } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import axios from "axios";
import { useDevice } from "src/hooks/useDevice";
import { useNavigate } from "react-router-dom";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import ReactMarkdown from "react-markdown";
import "./ActivityPage.scss";

import RichText from "src/components/RichText/RichText";
import Gallery from "src/components/Gallery/Gallery";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import {
  ActivityCategory,
  Activity,
  ActivityImageAttributeData,
  ActivityImageAttribute,
} from "src/components/Interfaces";

import { appSettings } from "../config";
import { getLocale } from "src/components/HeaderModule/HeaderModule";
import { LoadingSlider } from "src/components/LoadingSlider/LoadingSlider";
import { LoadingSpinner } from "src/components/LoadingSpinner/LoadingSpinner";
import { useActivitySettings } from "src/hooks/useActivitySettings";
import {getColorByType} from "../utils/activityColors";

function ActivityPage() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const activityId = params.get("id") ?? "";

  const queryClient = useQueryClient();
  const device = useDevice();

  const {
    isLoading: isActivityDataLoading,
    error: activityError,
    data: activityData,
  } = useQuery<Activity>(["activityData"], () =>
    axios
      .get(
        `${appSettings.cmsApiUrl}/activities/${activityId}?populate=*&locale=${getLocale()}`
      )
      .then((res) => {
        const returnValue = res.data;
        return returnValue.data;
      })
  );


  const {
    isLoading: isActivitySettingsLoading,
    error: activitySettingsError,
    data: activitySettingsData,
  } = useActivitySettings();

  useEffect(() => {
    queryClient.invalidateQueries(["activityData"]);
  }, [i18next.language]);

  // lightbox status
  const [index, setIndex] = React.useState(-1);

  const renderImages = (activityImage: ActivityImageAttribute) => {
    return (
      <div className="carousel-item" key={`image-${activityImage.id}`}>
        <img
          src={activityImage.attributes.url}
          alt="activity"
        />
      </div>
    );
  };

  const goBack = () => {
    navigate(-1);
  };

  if (isActivityDataLoading || isActivitySettingsLoading)
    return (
      <>
        <LoadingSlider />
        <LoadingSpinner />
      </>
    );

  if (activityError || activitySettingsError) return <span>"An error has occurred: "</span>;

  const renderContentElement = () => {
    return (
      <div className={`activity-content-wrapper`}>
        <RichText content={activityData?.attributes?.Content ?? ""} />
      </div>
    );
  };

  const renderImagesElement = () => {
    const slides = activityData?.attributes?.Images?.data?.map((activityImage) => {
        return {
            src: activityImage.attributes.url,
        };
    })

    return (
        <>
          {slides && slides.length > 0 && (
            <>
              <Gallery slides={slides} setIndex={setIndex} />
              <Lightbox open={index >= 0} index={index} close={() => setIndex(-1)} slides={slides} />
            </>
          )}
        </>
    )
  }

  const renderElementByName = (elementName: string) => {
    switch (elementName) {
      case "content":
        return renderContentElement();
      case "images":
        return renderImagesElement();
      case "worksheet":
       return renderWorkSheetElement();
    }
  };

  const renderElementsByCustomOrder = () => {
    const elements = new Array(3);

    if (activityData?.attributes?.ElementsOrder.content) {
      elements[activityData?.attributes?.ElementsOrder.content - 1] =
        renderContentElement();
      elements[activityData?.attributes?.ElementsOrder.images - 1] =
        renderImagesElement();
      elements[activityData?.attributes?.ElementsOrder.worksheet - 1] =
        renderWorkSheetElement();
    }

    return elements;
  };

  const renderWorkSheetElement = () => {
    const worksheets = activityData?.attributes?.Worksheet?.data || [];
    // @ts-ignore
    if (!worksheets || !worksheets.length > 0) return null;

    return (
        <div>

            <div className="worksheet-pdf-part">
              <div className="worksheet-item-header">{t("worksheetDownload")}</div>
              {worksheets.map((sheet: any) => (
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={sheet.attributes?.url}
                    className="worksheet-item-link"
                    key={sheet.attributes.id}
                >
                  <div className="worksheet-item">
                    <div className="worksheet-item-file-name">
                      {`${sheet.attributes?.name}`}
                    </div>
                    <div className="worksheet-item-file-icon" />
                  </div>
                </a>
              ))}
            </div>
      </div>
    );
  };

  const hasCustomOrder = activityData?.attributes?.ElementsOrder !== null;

  return (
    <div className="ActivityPage">
      <div className="page-content">
        <div className="page-header">
          <div className="back-icon" onClick={goBack} />
          <h1>{activityData?.attributes?.Header}</h1>
        </div>

        <div className="activity-tags">
            <span
                className="activity-tag"
                style={{
                  color:
                  activityData?.attributes?.activity_category?.data?.attributes
                      ?.color,
                  borderColor:
                  activityData?.attributes?.activity_category?.data?.attributes
                      ?.color,
                }}
            >
            {activityData?.attributes?.activity_category?.data?.attributes?.name}
          </span>
          {activityData?.attributes.type && (
              <span
                  className="activity-tag"
                  style={{
                      color: getColorByType(activityData?.attributes.type ?? ''),
                      borderColor: getColorByType(activityData?.attributes.type ?? ''),
                  }}
              >
            {t(`types.${activityData?.attributes.type}`)}
          </span>
          )}
        </div>


          {hasCustomOrder && renderElementsByCustomOrder()}
          {!hasCustomOrder && activitySettingsData?.map(setting => (
              renderElementByName(setting.attributes.element)
          ))}

      </div>
    </div>
  );
}

export default ActivityPage;
