import './MapScript.scss'

const MapScript = () => {
    return (
        <div className={`map-script--wrapper`}>
            <h1 className={`map-script--heading`}>Zapojené školky</h1>
            <div className={`map-script--inner`}>
                <iframe
                    allow="geolocation *; camera *;"
                    frameBorder="0"
                    src="https://www.mapotic.com/skolni-zahrady/embed-pro?zoom=7&lat=49.82984553249271&lng=15.79284667967201&fcat=6767">
                </iframe>
            </div>
        </div>
    )
}

export default MapScript;