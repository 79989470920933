export enum Size {
    Xs = 0,
    Sm = 1,
    Md = 2,
    Lg = 3,
    XLg = 4
}

export enum ResponsiveLayout {
    Mobile = 'Mobile',
    Tablet = 'Tablet',
    Desktop = 'Desktop',
    LargeDesktop = 'LargeDesktop'
}

export const Breakpoint: IBreakpoint[] = [{
    size: Size.Xs,
    width: 0,
    breakpointSlick: 576,
    visibility: "d-block d-sm-none",
},
{
    size: Size.Sm,
    width: 576,
    breakpointSlick: 992,
    visibility: "d-none d-sm-block d-md-none",
},
{
    size: Size.Md,
    width: 992,
    breakpointSlick: 1200,
    visibility: "d-none d-md-block d-lg-none",
},
{
    size: Size.Lg,
    width: 1200,
    breakpointSlick: 1920,
    visibility: "d-none d-lg-block",
},
{
    size: Size.XLg,
    width: 1400,
    breakpointSlick: 1920,
    visibility: "d-none d-lg-block",
}];

interface IBreakpoint {
    size: Size;
    width: number;
    breakpointSlick: number;
    visibility: string;
}
