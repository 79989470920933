import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { getLocale } from "src/components/HeaderModule/HeaderModule";
import { ActivityCategory } from "src/components/Interfaces";
import { appSettings } from "src/config";

export const useActivityCategories = () => {
    return useQuery<ActivityCategory[]>(["activityCategoriesData"], () =>
    axios
      .get(`${appSettings.cmsApiUrl}/activity-categories?locale=${getLocale()}`)
      .then((res) => {
        const returnValue = res.data;
        return returnValue.data;
      })
  );
}