import ReactMarkdown from "react-markdown";
import './RichText.scss'
import clsx from 'clsx'

interface RichTextProps {
    content: string
    className?: string
}

const RichText = (props: RichTextProps) => {
    return (
        <div className={clsx(`rich-text`, props.className)}>
            <ReactMarkdown>{props.content}</ReactMarkdown>
        </div>
    )
}

export default RichText