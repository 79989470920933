import * as React from 'react'
import './LoadingSlider.scss';

export const LoadingSlider = () => (
    <div className="loading-slider">
        <div className="line"></div>
        <div className="subline inc"></div>
        <div className="subline dec"></div>
    </div>
)
