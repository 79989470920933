import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useNavigate } from "react-router-dom";


import ReactMarkdown from "react-markdown";
import "./AboutUsPage.scss";
import { LoadingSlider } from "src/components/LoadingSlider/LoadingSlider";
import { LoadingSpinner } from "src/components/LoadingSpinner/LoadingSpinner";
import { usePages } from "src/hooks/usePages";
import { getPageContentByType } from "src/utils/renderUtils";
import RichText from "../components/RichText/RichText";

function AboutUsPage() {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const {
    isLoading: isPagesLoading,
    error: pagesError,
    data: pagesData,
  } = usePages();

  const goBack = () => {
    navigate(-1);
  };

  if (isPagesLoading)
    return (
      <>
        <LoadingSlider />
        <LoadingSpinner />
      </>
    );

  if (pagesError) return <span>"An error has occurred: "</span>;

  return (
    <div className="AboutUsPage">
      <div className="page-content">
        <div className="page-header">
          <div className="back-icon" onClick={goBack} />
          <h1>{t("aboutUs")}</h1>
        </div>

        {pagesData && (
            <RichText content={getPageContentByType(pagesData, "about-us-page")} className={'narrow'} />
        )}
      </div>
    </div>
  );
}

export default AboutUsPage;
