import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { getLocale } from "src/components/HeaderModule/HeaderModule";
import { Page } from "src/components/Interfaces";
import { appSettings } from "src/config";

export const usePages = () => {
  return useQuery<Page[]>(["pagesData"], () =>
    axios
      .get(`${appSettings.cmsApiUrl}/pages?locale=${getLocale()}`)
      .then((res) => {
        const returnValue = res.data;
        return returnValue.data;
      })
  );
};
